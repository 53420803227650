import AssociatedTeamsCard from '../templates/AssociatedTeamsCard';
import InsightCard from '../templates/InsightCard';
import PlayerTransfer from './PlayerTransfer';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Modal, Carousel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import PlayerIcon from "../images/player_icon.png";


export default function PlayerInfo({ playerinfo, insights }) {

    const [showPlayerTransferModal, setShowPlayerTransferModal] = useState(false);
    const [showPlayerTransferOffCanvas, setShowPlayerTransferOffCanvas] = useState(false);

    const handleClosePlayerTransferModal = () => setShowPlayerTransferModal(false);
    const handleShowPlayerTransferModal = () => setShowPlayerTransferModal(true);

    const handleClosePlayerTransferOffCanvas = () => setShowPlayerTransferOffCanvas(false);
    const handleShowPlayerTransferOffCanvas = () => setShowPlayerTransferOffCanvas(true);

    const { first_name, last_name, date_of_birth, team, image, positions = [] } = playerinfo;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [playerinfo]);

    const { ageFormatter } = HelperFunctions();
    const age = date_of_birth ? ageFormatter(date_of_birth) : '';
    const first_name_extracted = first_name.split(' ')[0];

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showInsightModal, setShowInsightModal] = useState(false);

    const handleSelect = (selectedIndex) => {
        setCarouselIndex(selectedIndex);
        handleShowInsightModal();
    };


    const handleCloseInsightModal = () => setShowInsightModal(false);
    const handleShowInsightModal = () => setShowInsightModal(true);

    return (
        <div>
            <div className='row row-cols-md-2 row-cols-1 gx-2 gy-4'>
                <div className='col d-flex align-items-center'>
                    <div className='w-100'>
                        <div className='d-flex flex-row align-items-center d-flex justify-content-md-start justify-content-between'>
                            <div className='d-flex align-items-center'>
                                {image ?
                                    <img className="object-fit-cover disc-55 disc-md-9 rounded-circle bg-white mb-0 mb-md-3 me-2 me-md-0" src={image} alt={first_name_extracted + ' ' + last_name } />
                                    :
                                    <img className=" disc-55 disc-md-9 mb-0 mb-md-3 me-2 me-md-0" src={PlayerIcon} alt={first_name_extracted + ' ' + last_name } />
                                }
                                <div className='d-flex flex-column align-items-start align-items-md-center px-md-4 ps-2'>
                                    <p className="d-block mb-0 text-left w-100 fw-normal">{first_name_extracted} <strong className='fw-semi-bold'>{last_name}</strong></p>
                                    <p className="mb-0 small text-left w-100">{age}{0 < age && 0 < positions.length ? <>&nbsp;&middot;&nbsp;</> : <></>}{positions.map(position => position.name).join('/')}</p>
                                 
                                </div>
                            </div>
                            { false && <div className='d-flex align-items-center'>
                                <i className="fa-light fa-share-nodes me-2 text-fio-error-100 cursor-pointer"></i>
                                <i className="fa-regular fa-grid-2-plus bg-secure-blue-500 p-2half rounded-circle cursor-pointer"></i>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className='d-md-flex align-items-center justify-content-end gap-3 order-md-0 order-3 col'>
                    <div className='w-100 w-px-md-360 mb-2'>
                      {team && <AssociatedTeamsCard team={team}/>}  
                    </div>
                    { false && <div className="bg-dark-mode-300 rounded-2 d-flex align-items-center justify-content-center">
                        <div className='d-md-flex d-none' onClick={handleShowPlayerTransferModal}>
                            <i className="fa-regular fa-clock-rotate-left py-4 px-5 cursor-pointer"></i>
                        </div>
                        <p className='cursor-pointer mb-0 d-md-none small py-2 px-3 d-flex'
                            onClick={handleShowPlayerTransferOffCanvas}>View transfer history</p>
                    </div> }
                    <div className='bg-dark-mode-300 rounded-2 d-md-none d-flex align-items-center justify-content-center'>
                    </div>
                </div>
                {insights?.map(function (object, i) {
                    return (
                        <div key={i} className={`col ${i === 1 ? 'd-none d-md-block' : ''}`} onClick={() => handleSelect(i)}>
                            <InsightCard playerInsights={true} insights={object} />
                        </div>
                    );
                })}
            </div>
            <Modal size="lg" show={showPlayerTransferModal} onHide={handleClosePlayerTransferModal}>
                <Modal.Body className=' bg-dark-mode-300 rounded-2 mt-3 mb-3 py-2 px-4 position-relative'>
                    <button type="button" className="d-none d-md-flex position-absolute z-index-1 end-0 top-0 disc-4 bg-zero-dark-300  disc-md-45 btn-close mt-n5 me-md-n3 me-n3"
                        onClick={handleClosePlayerTransferModal} ></button>
                    <div className='overflow-y-auto min-vh-25 max-vh-75'>
                        <div className='d-block d-md-none my-4  cursor-pointer'>
                            <div
                                className="small flex-0 "
                                onClick={handleClosePlayerTransferModal}
                            ><i className="me-3 fa-light fa-arrow-left small"></i> Back to player information</div>
                        </div>
                        <PlayerTransfer isInModal={true} transferInfo={playerinfo} />
                    </div>
                </Modal.Body>
            </Modal>
            <Offcanvas className="min-vh-100 max-vh-100 min-vw-100 max-vw-100 bg-dark-mode-800 rounded-2" show={showPlayerTransferOffCanvas} onHide={handleClosePlayerTransferOffCanvas} placement="end">
                <Offcanvas.Body className=' mt-3 mb-3 position-relative'>
                    <button type="button" className="d-none d-md-flex position-absolute z-index-1 end-0 top-0 disc-4 bg-zero-dark-300  disc-md-45 btn-close mt-n5 me-md-n3 me-n3"
                        onClick={handleClosePlayerTransferOffCanvas} ></button>
                    <div className='d-block d-md-none my-4'>
                        <div
                            className="small flex-0 cursor-pointer "
                            onClick={handleClosePlayerTransferOffCanvas}
                        ><i className="me-3 fa-light fa-arrow-left small"></i> Back to player information</div>
                    </div>
                    <PlayerTransfer isInModal={false} transferInfo={playerinfo} />
                </Offcanvas.Body>
            </Offcanvas>
            <Modal size="lg" centered show={showInsightModal} onHide={handleCloseInsightModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseInsightModal} ></button>
                    <div className='p-md-3'>
                        <Carousel activeIndex={carouselIndex} onSelect={handleSelect}>
                            {
                                insights.map(function (insight, i) {
                                    return <Carousel.Item
                                        key={i}
                                    >
                                        <div className='my-55 mx-65'>
                                            <p>{insight}</p>
                                        </div>
                                    </Carousel.Item>
                                })
                            }
                        </Carousel>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
