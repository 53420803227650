import { intervalToDuration } from 'date-fns';

const HelperFunctions = () => {

    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
    const dateFormatter = (datestr) => {

        const suffix = {
            one: 'st',
            two: 'nd',
            few: 'rd',
            other: 'th',
        };

        var theDate = new Date(datestr);

        return new Intl.DateTimeFormat('en-GB', { day: 'numeric' }).format(theDate) + suffix[new Intl.PluralRules('en-GB', { type: 'ordinal' }).select(theDate.getDate())] + ' ' + new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(theDate) + ' ' + theDate.getFullYear();
    }
    const ageFormatter = (dateOfBirth) => {
        const dob = new Date(dateOfBirth);
        const diffMs = Date.now() - dob.getTime();
        const ageDt = new Date(diffMs);
        return Math.abs(ageDt.getUTCFullYear() - 1970);
    };

    const dateFormatterRelative = (datestr) => {

        let date = new Date(datestr);

        var ONE_DAY = 1000 * 60 * 60 * 24;

        // Calculate the difference in milliseconds
        var differenceMs = Math.abs(new Date().valueOf() - date);

        // Convert back to days
        var daysDifference = Math.round(differenceMs / ONE_DAY);

        if (0 === daysDifference) {
            return 'today'
        } else if (1 === daysDifference) {
            return 'yesterday'
        } else {
            return daysDifference + ' days ago'
        }


    };

    const durationFormatter = (timeStamp) => {

        let seconds = new Date().valueOf() - timeStamp;
        let duration = intervalToDuration({ start: 0, end: seconds });

        if (duration.year) {
            return `${duration.year}y`;
        } else if (duration.months) {
            return `${duration.months}M`;
        } else if (duration.days) {
            return `${duration.days}d`;
        } else if (duration.hours) {
            return `${duration.hours}h`;
        } else if (duration.minutes) {
            return `${duration.minutes}m`;
        } else {
            return `${duration.seconds}s`;
        }

    };

    function number_format (number, decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    const amountFormatter = (value) => {
         // Convert to millions if greater than 1 million
         if (value >= 1000000) {
            return `£${number_format(value / 1000000, 2)}M`;
        }
        // Convert to thousands if greater than 1 thousand
        else if (value >= 1000) {
            return `£${number_format(value / 1000, 2)}K`;
        }

        // Otherwise, return the raw value with the pound symbol
        return `£${number_format(value, 2)}`;
    }

   


    const statsFormatter = (stat) => {
        let sentence = stat.replace(new RegExp('_', 'g'), ' ').replace(new RegExp('-', 'g'), ' ').split(/(?=[A-Z])/).join(' ').toLowerCase();
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    };

    const stringTruncate = (str, n = 100) => {
        if (!str) return ''; // Return an empty string if str is undefined or null
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    const termTruncate = (str, n = 100) => {
        if (!str) return ''; // Return an empty string if str is undefined or null
        return (str.length > n) ? `"${str.slice(0, n - 1)}...` : `"${str}"`;
    };
    function formatValue(value) {
        if (value === undefined || value === null) {
            return 'N/A';
        }
        if (value === 0) {
            return '0';
        }
        if (typeof value === 'number') {
            return value.toLocaleString();
        }
        return value;
    }

    const valueToQueryStringParam = (value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            return value.toString();
        } else {
            return JSON.stringify(value)
        }

    }

    const buildQueryStringFromFilterObj = (filterObj, excludeOrigin) => {

        let stateString = '';
        let foundProperties = [];

        if (0 < window.location.search.length) {

            const params = new URLSearchParams(window.location.search);

            //loop through existing query string params and modify them if appropiate
            for (const [key, value] of params.entries()) {

                if (key in filterObj) {
                    stateString = stateString + key + "=" + valueToQueryStringParam(filterObj[key]) + '&';
                    foundProperties.push(key);
                } else {
                    stateString = stateString + key + "=" + value + '&';
                }
            }

        }

        //now add those properties that are not present in the querystring
        for (const property in filterObj) {
            if (-1 === foundProperties.indexOf(property)) {
                stateString = stateString + property + "=" + valueToQueryStringParam(filterObj[property]) + '&';
            }
        }


        if (0 < stateString.length) {
            stateString = '?' + stateString.substring(0, stateString.length - 1);
        } else {
            if (!excludeOrigin)
                stateString = window.location.href.split('?')[0];
        }

        return stateString;

    }
    const firstNameExtracted = (first_name) => {
        return first_name ? first_name.split(' ')[0] : '';
    }

    /**
     * Checks if a given element has a CSS class.
     * 
     * @param element the element
     * @param className the CSS class name
     */

    const hasClass = (element, className) => {
        if (element.classList) return !!className && element.classList.contains(className);
        return (" " + (element.className.baseVal || element.className) + " ").indexOf(" " + className + " ") !== -1;
    }

    /**
     * Adds a CSS class to a given element.
     * 
     * @param element the element
     * @param className the CSS class name
     */

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className); else if (!hasClass(element, className)) if (typeof element.className === 'string') {element.className = element.className + " " + className;} else {element.setAttribute('class', ((element.className && element.className.baseVal) || '') + " " + className)};
    }

    const replaceClassName = (origClass, classToRemove) => {
        return origClass.replace(new RegExp("(^|\\s)" + classToRemove + "(?:\\s|$)", 'g'), '$1').replace(/\s+/g, ' ').replace(/^\s*|\s*$/g, '');
      }

    /**
     * Removes a CSS class from a given element.
     * 
     * @param element the element
     * @param className the CSS class name
     */
    const removeClass = (element, className) => {
        if (element.classList) {
            element.classList.remove(className);
        } else if (typeof element.className === 'string') {
            element.className = replaceClassName(element.className, className);
        } else {
            element.setAttribute('class', replaceClassName((element.className && element.className.baseVal) || '', className));
        }
    }

    const addClassToBody = (el,className) => {       
        addClass((el.ownerDocument || document).body, className);
    }
    const removeClassFromBody = (el,className) => {
        removeClass((el.ownerDocument || document).body, className);
    }

    const getBodyScrollbarWidth = (ownerDocument) => {
        const window = ownerDocument.defaultView;
        return Math.abs(window.innerWidth - ownerDocument.documentElement.clientWidth);
      }

    return { kFormatter, dateFormatter, dateFormatterRelative, stringTruncate, termTruncate, ageFormatter, amountFormatter, statsFormatter, formatValue, durationFormatter, buildQueryStringFromFilterObj, firstNameExtracted, addClassToBody, removeClassFromBody ,getBodyScrollbarWidth};

}
export default HelperFunctions;