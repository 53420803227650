import React, { useState, useRef } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import SortableTableHeader from '../components/grids/SortableTableHeader';
import Dropdown from 'react-bootstrap/Dropdown';


const TeamPlayerInformation = ({ data = {} }) => {

    const playerInformationAreaRef = useRef(null);

    const countShowOnLoad = 2;
    const [showMorePlayers, setShowMorePlayers] = useState(false);

    const { ageFormatter, amountFormatter, statsFormatter } = HelperFunctions();

    const squad_players = data.squad_players.map(obj => ({ ...obj, position: 0 < obj.positions.length ? obj.positions[0].name : '', full_name: `${obj.first_name || ''} ${obj.last_name || ''}`.trim(), contract_end_year: obj.contract_ends ? new Date(obj.contract_ends).getFullYear() : 'N/A' , age : ageFormatter(obj.date_of_birth) }));

    const [selectedIndex, setSelectedIndex] = useState(null);

    const [sortColumn, setSortColumn] = useState('squad_no');
    const [sortType, setSortType] = useState('asc');

    const [filterState, setFilterState] = useState({
        players: { value: null },
        contract_end_year: { value: null },
        position: { value: null },
        age: { value: null }
    });


    const resetFilter = () => {
        setFilterState((prevState) => ({
            players: { value: null },
            contract_end_year: { value: null },
            position: { value: null },
            age: { value: null }
        }));
    }

    const applyPlayerFilter = (text, e) => {
        e.preventDefault();

        setFilterState((prevState) => ({
            ...prevState,
            players: { value: text },
        }));

        return true;

    }

    const applyContractEndYearFilter = (text, e) => {
        e.preventDefault();

        setFilterState((prevState) => ({
            ...prevState,
            contract_end_year: { value: text },
        }));

        return true;

    }

    const applyPositionFilter = (text, e) => {
        e.preventDefault();

        setFilterState((prevState) => ({
            ...prevState,
            position: { value: text },
        }));

        return true;

    }

    const applyAgeFilter = (text, e) => {
        e.preventDefault();

        setFilterState((prevState) => ({
            ...prevState,
            age: { value: text },
        }));

        return true;

    }

    const filterPlayerArray = (item) => {

        let good = true;

        if (filterState.players.value) {
           good = good && item.full_name.toLowerCase().includes(filterState.players.value.toLowerCase());
        }

        if (filterState.contract_end_year.value) {
          good = good && String(item.contract_end_year).toLowerCase().includes(String(filterState.contract_end_year.value).toLowerCase());
        }

        if (filterState.position.value) {
            
            good = good && item.position.toLowerCase().includes(filterState.position.value.toLowerCase());
        }

        if (filterState.age.value) {
            good = good && String(item.age).toLowerCase().includes(String(filterState.age.value).toLowerCase());
          }

        return good;

    }


 

    const sortedData = squad_players.sort(function (a, b) {
        return 'asc' === sortType ? 'number'  === typeof a[sortColumn] ?  a[sortColumn] - b[sortColumn] :   String(a[sortColumn]).localeCompare(String(b[sortColumn])) :  'number'  === typeof a[sortColumn] ?  b[sortColumn] - a[sortColumn] : String(b[sortColumn]).localeCompare(String(a[sortColumn]));

    });

    const PlayerInformationRow = ({ item, i }) => {
        const { squad_no, full_name, age, position, uuid, valuation, contract_end_year } = item;

        return (<><Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`rounded-md-2 rounded-top-end-0  rounded-bottom-end-0 p-2 text-decoration-none bg-dark-mode-400 mb-2 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}><div className='p-2'>{squad_no}#</div></Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{full_name}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{age}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{statsFormatter(position)}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{contract_end_year}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`rounded-md-2 rounded-top-start-0  rounded-bottom-start-0 p-2 text-decoration-none bg-dark-mode-400 mb-2 d-flex justify-content-between align-items-center border-start-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${item.uuid}`}><div>{amountFormatter(valuation)}</div><i className="white fa-regular fa-arrow-up-right text-neutral-gray-800"></i></Link></>
        )
    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);

        return true;
    }

    return (
        <>{0 < sortedData.length &&
            <div className='mb-5 position-relative' ref={playerInformationAreaRef} >
                <h3 className='fw-semi-bold '>Player information</h3>

                <div className='d-flex justify-content-start'>
                <div className='border-0 bg-dark-mode-600 fw-normal small me-2 center-content px-3 cursor-pointer'  onClick={resetFilter}>All players</div>
                    <Dropdown className='me-2' onSelect={applyPlayerFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Players
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All players</Dropdown.Item>
                            {[...new Set(squad_players.map((item) => { return { full_name: item.full_name, last_name: item.last_name } }))].sort(function (a, b) {
                                return a.last_name.localeCompare(b.last_name)
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={item.full_name}>{item.full_name}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                   
                    <Dropdown className='me-2' onSelect={applyPositionFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Position
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All positions</Dropdown.Item>
                            {[...new Set(squad_players.map((item) => item.position))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{statsFormatter(item)}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyAgeFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Age
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All ages</Dropdown.Item>
                            {[...new Set(squad_players.map((item) => item.age))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyContractEndYearFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Contract end
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All years</Dropdown.Item>
                            {[...new Set(squad_players.map((item) => item.contract_end_year))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="d-grid-md grid-gap-0 grid-md-6-cols fixed-1-row-6">
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'squad_no'} label={'No'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'last_name'} label={'Player name'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'date_of_birth'} label={'Age'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'position'} label={'Position'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'contract_ends'} label={'Contract end'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'valuation'} label={'Valuation'} sort_column={sortColumn} sort_type={sortType} />

                    {sortedData.filter(filterPlayerArray).slice(0, countShowOnLoad).map((item, i) => {

                        return (<PlayerInformationRow key={i} item={item} i={i} />)
                    })}
                    {sortedData.filter(filterPlayerArray).length > countShowOnLoad && showMorePlayers &&
                        <>
                            {sortedData.filter(filterPlayerArray).slice(countShowOnLoad, sortedData.filter(filterPlayerArray).length).map(function (item, i) {
                                return (
                                    <PlayerInformationRow key={i} item={item} i={i} />
                                )
                            })
                            }
                        </>
                    }
                    {sortedData.filter(filterPlayerArray).length > countShowOnLoad && !showMorePlayers &&
                        <div className='position-absolute start-0 bottom-0 w-100 gradient-overlay'>
                            <div className='center-content '>
                                <span className='bg-crayola-red-500 cursor-pointer rounded-4 py-3 px-4 small fw-semi-bold' onClick={() => setShowMorePlayers(true)}>Show all</span>
                            </div>
                        </div>
                    }


                </div>
                {showMorePlayers &&
                    <div className='center-content'>
                        <span className='bg-crayola-red-500 cursor-pointer rounded-4 py-3 px-4 small fw-semi-bold' onClick={() => {
                            window.scrollTo({ top: playerInformationAreaRef.current.getBoundingClientRect().top, behavior: "smooth" });
                            setShowMorePlayers(false);
                        }}>Show less</span>
                    </div>
                }

            </div>
        }
        </>
    )
}

export default TeamPlayerInformation;