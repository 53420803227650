import React, { useEffect, useRef, useState } from 'react'
import { apiService } from '../../services/apiService';
import { authenticationService } from '../../services/authenticationService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import TeamCardTypeahead from "../../templates/TeamCardTypeahead";
import { Link } from 'react-router-dom';


const SearchReports = ({ onError }) => {
    const [options, setOptions] = useState([]);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const refTypeahead = useRef(null);

    const handleSearch = query => {
        setSearchPerformed(true);

        apiService.apiGet(`${process.env.REACT_APP_API_URL}/team/typeahead_search?search_query=${query}`)
            .then((items) => {
                setOptions(items.data)
                //setIsLoading(false)
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        if (onError) onError(error.message + ' error');
                    }
                })
    }

    useEffect(() => {
        refTypeahead?.current?.clear();
    }, []);

    const filterBy = () => true;

    return (
        <div className='d-flex align-items-center justify-content-center mt-9'>
            <div className='d-flex flex-column justify-content-center bg-dark-mode-600 py-5 px-4 rounded-4 max-w-f-600 w-100'>
                <h4>Start your transfer analysis</h4>
                <p className='mb-5 text-neutral-gray-700 fw-semi-bold small'>Search for a team and click to begin your transfer analysis.</p>
                <div className="react-bootstrap-typeahead-desktop w-100">
                    <AsyncTypeahead
                        ref={refTypeahead}
                        filterBy={filterBy}
                        id="async-players"
                        isLoading={false}
                        open={options.length > 0 || searchPerformed}
                        labelKey={(option) => `${option?.name}`}
                        minLength={3}
                        onSearch={handleSearch}
                        options={options}
                        placeholder="Search for a team..."
                        onInputChange={(text) => {
                            if (text.trim() === "") {
                                setOptions([]); // Clear options when input is empty
                                setSearchPerformed(false); // Reset search state
                            }
                        }}
                        inputProps={{
                            className: "dark py-25 text-white bg-dark-mode-50 search-query bg-dark-mode-200 border border-dark-mode-50 fw-normal form-control form-control-lg w-100 rounded-pill",
                        }}
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                            <input
                                {...inputProps}
                                ref={(input) => {
                                    // Be sure to correctly handle these refs. In many cases, both can simply receive
                                    // the underlying input node, but `referenceElementRef can receive a wrapper node if
                                    // your custom input is more complex (See TypeaheadInputMulti for an example).
                                    inputRef(input);
                                    referenceElementRef(input);
                                }}
                            />
                        )}
                        menuProps={{
                            className: 'bob'
                        }}
                        renderMenu={(results) => (
                            <div className="w-100 max-h-px-360 h-100 overflow-y-auto">
                                {results.length > 0 ? (
                                    results.map((info, index) => (
                                        <Link
                                            to={`/reports/team-transfers-report/${info.id}`}
                                            key={index}
                                            className="d-flex flex-column justify-content-between text-decoration-none border-bottom border-dark-mode-200"
                                        >
                                            <TeamCardTypeahead data={info} />
                                        </Link>
                                    ))
                                ) : searchPerformed ? (
                                    <div className="text-center py-3 text-white">No results</div>
                                ) : null}
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default SearchReports