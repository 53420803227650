import React, { useEffect, useState, useRef } from "react"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { apiService } from '../services/apiService';
import { authenticationService } from '../services/authenticationService';
import { useNavigate } from 'react-router-dom';
import PlayerCardTypeahead from '../templates/PlayerCardTypeahead';

const PlayerSearchTypeahead = ({ onError, onSelect, isMobileView = false }) => {

    const navigate = useNavigate();

    const [options, setOptions] = useState([])

    const refTypeahead = useRef(null);

    const handleSearch = query => {
        //setIsLoading(true);
        // `http://sports.fenix:8888/football/search_player?search_query=${query}`
        //`${process.env.REACT_APP_API_URL}/player/typeahead_search?search_query=${query}`
        apiService.apiGet(`${process.env.REACT_APP_API_URL}/player/typeahead_search?search_query=${query}`)
            .then((items) => {
                setOptions(items.data.players)
                //setIsLoading(false)
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        if (onError) onError(error.message + ' error');
                    }
                })
    }

    const playerSelectHandle = (selected) => {
        navigate('/playerdetail/' + selected[0].uuid);
        refTypeahead.current.clear();
        if (onSelect) onSelect();
    }

    useEffect(() => {
        refTypeahead.current.clear();
    }, []);

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true

    return (
        <AsyncTypeahead
            ref={refTypeahead}
            filterBy={filterBy}
            id="async-players"
            isLoading={false}
            labelKey={option => `${option.firstName} ${option.lastName}`}
            minLength={3}
            onSearch={handleSearch}
            options={options}
            onChange={(selected) => {
                // Handle selections...               
                playerSelectHandle(selected);
            }}
            placeholder="Search for a player..."
            inputProps={{
                className: 'dark  bg-neutral-gray-900 border border-neutral-gray-900 fw-normal form-control form-control-md w-100',
                style: {
                    'backgroundColor': 'red',
                  }
            }}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <input
                    {...inputProps}
                    ref={(input) => {
                        // Be sure to correctly handle these refs. In many cases, both can simply receive
                        // the underlying input node, but `referenceElementRef can receive a wrapper node if
                        // your custom input is more complex (See TypeaheadInputMulti for an example).
                        inputRef(input);
                        referenceElementRef(input);
                    }}
                />
            )}

            menuProps={{
                className: 'bob'
            }}
            renderMenuItemChildren={info => (                                
                <div className="d-flex flex-column justify-content-between bg-dark-mode-300 rounded-2 min-w-px-300 w-100">
                    <PlayerCardTypeahead data={info} noImg={true}/>                   
                </div>                
            )}

          
          
        />
    )
}
/* example-end */

export default PlayerSearchTypeahead
