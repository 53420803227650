import PlayerIcon from "../../images/player_icon.png";
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import HelperFunctions from "../../helpers/HelperFunctions";
export default function NewsFeedNewsSummaryCard({ props, data = {}, following = false }) {

    const { following_name, following_image, following_uuid, text, sources, date } = { ...data };

    const { dateFormatter } = HelperFunctions();
    const sourceShowNOnLoad = 3;
    const imageLoadError = (e) => {
        e.target.src = PlayerIcon;
    };

    const [showMoreModal, setShowMoreModal] = useState(false);

    const handleCloseMoreModal = (e) => {
        e.stopPropagation();
        setShowMoreModal(false);
    };

    const handleShowMoreModal = (e) => {
        e.stopPropagation();
        setShowMoreModal(true);
    };

    return (
        <>
            <div className="rounded-3 bg-dark-mode-300  h-100 w-100 p-3 d-flex flex-column justify-content-between" >
                <div>
                    <Link to={`/playerdetail/` + following_uuid} className={`d-flex bg-dark-mode-300 text-decoration-none d-flex flex-row align-items-center justify-content-start rounded-4 w-auto mb-3`}>
                        <div className="position-relative me-2">
                            {following_image ?
                                <img className={`object-fit-cover disc-5 disc-md-55 rounded-circle`} onError={imageLoadError} src={following_image} alt={following_name} />
                                :
                                <img className={`object-fit-cover disc-5 disc-md-55 rounded-circle`} src={PlayerIcon} alt={following_name} />

                            }
                            {following &&
                                <div className='bg-fio-success-500 rounded-circle disc-3 p-1 center-content position-absolute bottom-0 end-0'> <i className='fa-light fa-check  small'></i></div>
                            }
                        </div>

                        <p className="mb-0 me-3 small fw-normal">
                            {following_name}
                        </p>
                    </Link>
                    <p className="small mb-0 ">{text}</p>
                    {date &&
                        <div className="d-flex justify-content-end align-items-center mt-2 mb-0"><div className="small text-neutral-gray-700">{dateFormatter(date)}</div></div>
                    }
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="text-crayola-red-400 border-crayola-red-400 border rounded-pill px-2 py-1 small fw-normal">
                        AI generated <i className="fa-light fa-sparkles mx-1"></i>
                    </div>
                    {sources && 0 < sources.length &&
                   
                        <div className="d-flex align-items-center justify-content-end ">
                            <div className="d-flex justify-content-between align-items-center" >
                                <span className='text-neutral-gray-700 extra-small pe-3 text-nowrap'>{sources.length} source{sources.length === 1 ? '' : 's'}</span>
                                <div className=" d-print-none">
                                    <div className="center-content">
                                        {sources.slice(0, sourceShowNOnLoad).map(function (object, i) {
                                            return <a key={i} target='_blank' rel='noreferrer' href={object.source_url} title={object.source_url}><img className={`object-fit-cover disc-45 disc-md-5 rounded-circle border border-2 border-neutral-gray-700 ms-n2`} src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + object.source_url + '&size=64'} alt={''} /></a>
                                        })
                                        }
                                        {sources.length > sourceShowNOnLoad &&
                                            <div className='disc-45 disc-md-5 fw-normal center-content rounded-circle border border-2 border-neutral-gray-700  small text-nowrap cursor-pointer ms-n2 bg-dark-mode-300' onClick={handleShowMoreModal}>{`+${sources.length - sourceShowNOnLoad}`}</div>
                                        }
                                    </div>
                                </div>
                                <div className=" d-none d-print-block">
                                    <div className="center-content">
                                        {sources.slice(0, 1).map(function (object, i) {
                                            return <a key={i} target='_blank' rel='noreferrer' href={object.source_url} title={object.source_url}><img className={`object-fit-cover disc-45 disc-md-5 rounded-circle border border-2 border-neutral-gray-700 ms-n2`} src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + object.source_url + '&size=64'} alt={''} /></a>
                                        })
                                        }
                                        {sources.length > 1 &&
                                            <div className='disc-45 disc-md-5 fw-normal center-content rounded-circle border border-2 border-neutral-gray-700  small text-nowrap cursor-pointer ms-n2 bg-dark-mode-300' onClick={handleShowMoreModal}>{`+${sources.length - 1}`}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                       
                    }
                </div>
            </div>
            <Modal size="md" centered show={showMoreModal} onHide={handleCloseMoreModal}>
                <Modal.Body className="bg-dark-mode-200 rounded-2 position-relative">
                    <button
                        type="button"
                        className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-300 text-black disc-md-45 btn-close-dark mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseMoreModal}
                    ></button>
                    <div className="d-flex flex-wrap">
                        {sources.map((object, i) => (
                            <a
                                key={i}
                                target="_blank"
                                rel="noreferrer"
                                href={object.source_url}
                                title={object.source_url}
                            >
                                <img
                                    className="mt-1 object-fit-cover disc-45 disc-md-5 rounded-circle border border-2 border-neutral-gray-700 ms-n2"
                                    src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + object.source_url + '&size=64'}
                                    alt=""
                                />
                            </a>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
