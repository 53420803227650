import HelperFunctions from '../helpers/HelperFunctions';
import CountryFlag from 'react-country-flag';


export default function MorePlayerInformation({ props, playerinfo = {} }) {

    console.log(playerinfo);
    
    const { dateFormatter } = HelperFunctions();
    const expiredate = playerinfo?.contract_expiry ? dateFormatter(playerinfo?.contract_expiry) : "N/A";
    return (
        <div>
            <p className="large d-md-block d-none fw-semi-bold">More player information</p>
            <p className="small d-md-none d-block fw-semi-bold mb-2">More player information</p>

            <div className="d-grid grid-2-cols bg-dark-mode-300 grid-gap-0 rounded-2">
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center">Contract expires </div>
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none">Contract expires </div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center">{expiredate}</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none">{expiredate}</div>
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center bg-dark-mode-500 rounded rounded-top-left-0 rounded-top-right-0 rounded-bottom-right-0">Citizenships</div>
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none bg-dark-mode-500 rounded rounded-top-left-0 rounded-bottom-right-0">Citizenships</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center bg-dark-mode-500 rounded rounded-bottom-left-0 rounded-top-right-0 rounded-bottom-left-0 rounded-top-left-0">
                    {playerinfo?.nationalities?.map((nationality, index) => {
                        return (
                            <div key={index}>
                                <CountryFlag
                                    countryCode={nationality.code2}
                                    svg
                                    className="disc-4 me-2"
                                    title={nationality.name}
                                />
                                {nationality.name}
                                {index < playerinfo.nationalities.length - 1 && <span className="mx-2">/</span>}
                            </div>
                        )
                    })}
                </div>

                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none bg-dark-mode-500 rounded rounded-bottom-left-0 rounded-bottom-left-0 rounded-top-left-0">
                    {playerinfo?.nationalities?.map((nationality, index) => {
                        return (
                            <div key={index}>
                                <CountryFlag
                                    countryCode={nationality.code2}
                                    svg
                                    className="disc-4 me-2"
                                    title={nationality.name}
                                />
                                {nationality.name}
                                {index < playerinfo.nationalities.length - 1 && <span className="mx-2">/</span>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
