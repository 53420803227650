import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import HelperFunctions from "../helpers/HelperFunctions";

export default function LeagueStatistics({ props, stats }) {
    const { formatValue } = HelperFunctions();

    const getSeasonYear = (league) => {
        const match = league.match(/\d{2}\/\d{2}/);
        return match ? parseInt(match[0].split("/")[0]) : 0;
    };

    const allStats = Object.entries(stats || {})
        .filter(([_, data]) => data.length > 0)
        .sort(([leagueA], [leagueB]) => getSeasonYear(leagueB) - getSeasonYear(leagueA)) // Sort leagues by season year
        .flatMap(([league, data]) =>
            data.map((item) => ({ league, ...item }))
        );

    const initialActiveKey = allStats.length > 0 ? `${allStats[0].league}-${allStats[0].season_id}` : null;
    const [activeAccordionKey, setActiveAccordionKey] = useState(initialActiveKey);

    useEffect(() => {
        if (initialActiveKey) {
            setActiveAccordionKey(initialActiveKey);
        }
    }, [initialActiveKey]);

    function CustomToggle({ children, eventKey }) {
        const isCurrent = activeAccordionKey === eventKey;

        const decoratedOnClick = useAccordionButton(eventKey, () => {
            setActiveAccordionKey(isCurrent ? null : eventKey);
        });

        return (
            <button
                type="button"
                className="bg-dark-mode-500 border-0 mb-3 w-100 rounded-2 p-0"
                onClick={decoratedOnClick}
            >
                <div className="d-flex justify-content-between align-items-center  py-md-3 p-2half px-md-4">
                    {children}
                    <i className={`fa ${isCurrent ? 'fa-minus' : 'fa-plus'} text-white`}></i>
                </div>
            </button>
        );
    }


    return (
        <div>
            <p className="large d-md-block d-none fw-semi-bold">Cup and league statistics</p>
            <p className="small d-md-none d-block fw-semi-bold mb-2">Cup and league statistics</p>

            <Accordion activeKey={activeAccordionKey}>
                {allStats.map((item, index) => {
                    const uniqueKey = `${item.league}-${item.season_id}`;
                    return (
                        <div key={uniqueKey}>
                            <CustomToggle eventKey={uniqueKey}>
                                <div className='d-flex align-items-center justify-content-between text-white'>
                                    <div className="d-flex flex-row align-items-center">
                                        <img className="object-fit-contain bg-white rounded-1 w-px-58 h-px-58 mb-0 me-3" src={item?.season?.competition?.image} alt={item} />
                                        <div className='d-flex flex-column align-items-start'>
                                            <p className="mb-0 d-md-block d-none">{item.league}</p>
                                            <p className="mb-0 d-md-none text-start d-block small">{item.league}</p>
                                            <div className="center-content">
                                                <ReactCountryFlag
                                                    countryCode={item?.season?.competition?.country?.code2}
                                                    svg
                                                    className="disc-4"
                                                    title={item?.season?.competition?.country?.name}
                                                />
                                                <p className="mb-0 text-neutral-gray-700 ms-2 fw-normal small d-md-block d-none">{item?.season?.competition?.country?.name}</p>
                                                <p className="mb-0 text-neutral-gray-700 ms-2 fw-normal extra-small d-md-none d-block">{item?.season?.competition?.country?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomToggle>
                            <Accordion.Item eventKey={uniqueKey} className="border-0 bg-dark-mode-600">
                                <Accordion.Body className="py-md-4 mb-3">
                                    <div className="row row-cols-md-3 row-cols-1 gx-4 px-md-3 px-2half">
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Games (appearances)</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Games (appearances)</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Minutes</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Minutes</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Minutes</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.minutesPlayed)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small"> {formatValue(item.minutesPlayed)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Appearances</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Appearances</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Appearances</div>
                                                </div>
                                                <div className=" d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.appearances)}</div>
                                                <div className="  d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.appearances)}</div>
                                                <div className=" d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Starting line-ups</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Starting line-ups</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Starting line-ups</div>
                                                </div>
                                                <div className=" d-md-flex d-none align-items-center fw-normal small">{formatValue(item.matchesStarted)}</div>
                                                <div className="  d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.matchesStarted)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Goals</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Goals</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total goals</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total goals</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total goals</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.goals)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.goals)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Goals conceded</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Goals conceded</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Goals conceded</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.goalsConceded)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.goalsConceded)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Assists</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Assists</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Assists</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.assists)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.assists)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Discipline</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Discipline</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Yellow cards</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Yellow cards</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Yellow cards</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.yellowCards)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.yellowCards)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Second yellows</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Second yellows</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Second yellows</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.yellowRedCards)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.yellowRedCards)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Red cards</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Red cards</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Red cards</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.redCards)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.redCards)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Tackles</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Tackles</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total tackles</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total tackles</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total tackles</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.tackles)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.tackles)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Blocked shots</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Blocked shots</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Blocked shots</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.blockedShots)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.blockedShots)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Interceptions</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Interceptions</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Interceptions</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.interceptions)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.interceptions)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Fouls</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Fouls drawn</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Fouls drawn</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Fouls drawn</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.wasFouled)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.wasFouled)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Fouls commited</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Fouls commited</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Fouls commited</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.fouls)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.fouls)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Dribbling</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Dribbling</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Successful dribble %</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Successful dribble %</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Successful dribble %</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.successfulDribblesPercentage)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.successfulDribblesPercentage)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Successful dribbles</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Successful dribbles</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Successful dribbles</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.successfulDribbles)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.successfulDribbles)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Dribbled past</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Dribbled past</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Dribbled past</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.dribbledPast)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.dribbledPast)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Duels</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Duels</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total duels won %</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total duels won %</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total duels won %</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.totalDuelsWonPercentage)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.totalDuelsWonPercentage)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Duels won</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Duels won</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Duels won</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.totalDuelsWon)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.totalDuelsWon)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Shots</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Shots</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total shots</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total shots</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total shots</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.totalShots)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.totalShots)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Shots on target</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Shots on target</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Shots on target</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.shotsOnTarget)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.shotsOnTarget)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-0 mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Penalties</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Penalties</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties scored</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties scored</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties scored</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.penaltyGoals)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.penaltyGoals)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties taken</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties taken</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties taken</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.penaltiesTaken)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.penaltiesTaken)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties won</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties won</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties won</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.penaltyWon)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.penaltyWon)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties commited</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties commited</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties commited</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.penaltyConceded)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.penaltyConceded)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties saved</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties saved</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties saved</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.penaltySave)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.penaltySave)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Passes</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Passes</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total passes</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total passes</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total passes</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.totalPasses)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.totalPasses)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Key passes</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Key passes</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Key passes</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.keyPasses)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.keyPasses)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Accurate passes %</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Accurate passes %</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Accurate passes %</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.accuratePassesPercentage)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.accuratePassesPercentage)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    );
                })}
            </Accordion>
        </div>
    );
}
