import React, { useState } from 'react'
import InsightListing from '../components/InsightListing'
import Modal from '../templates/Modal';
import Breadcrumb from '../templates/Breadcrumb';

const AIInsightsListing = () => {
  const [modalMessage, setModalMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'AI insights' }
  ];
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {showModal &&
        <Modal message={modalMessage} onClose={() => setShowModal(false)} />
      }
      <InsightListing onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
  )
}

export default AIInsightsListing