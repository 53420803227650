import React, { useState } from 'react'
import SearchReports from '../components/Reports/SearchReports'
import Modal from '../templates/Modal';
import Breadcrumb from '../templates/Breadcrumb';

const SearchTransferReport = () => {

    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Reports', to: '/reports' },
        { label: 'Transfer report' }
    ];
    return (
        <div>
            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            <Breadcrumb items={breadcrumbItems} />
            <SearchReports onError={(message) => { setShowModal(true); setModalMessage(message) }} />
        </div>
    )
}

export default SearchTransferReport