import React, { useState } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import SortableTableHeader from '../components/grids/SortableTableHeader';
import Dropdown from 'react-bootstrap/Dropdown';

const TeamPlayerTransfers = ({ data = {} }) => {


    const { ageFormatter, dateFormatter,amountFormatter, statsFormatter } = HelperFunctions();

    const transfers_in = data.transfers_in.map(obj => ({ ...obj, position: 0 < obj.positions.length ? obj.positions[0].name : '', full_name: `${obj.first_name || ''} ${obj.last_name || ''}`.trim(), age: ageFormatter(obj.date_of_birth) }));
    const transfers_out = data.transfers_out.map(obj => ({ ...obj, position: 0 < obj.positions.length ? obj.positions[0].name : '', full_name: `${obj.first_name || ''} ${obj.last_name || ''}`.trim(), age: ageFormatter(obj.date_of_birth) }));

    const [selectedIndex, setSelectedIndex] = useState(null);

    const [sortColumn, setSortColumn] = useState('last_name');
    const [sortType, setSortType] = useState('asc');

    const [sortColumnOut, setSortColumnOut] = useState('last_name');
    const [sortTypeOut, setSortTypeOut] = useState('asc');

    const sortedTransfersInData = transfers_in.sort(function (a, b) {
        return 'asc' === sortType ? 'number'  === typeof a[sortColumn] ?  a[sortColumn] - b[sortColumn] :   String(a[sortColumn]).localeCompare(String(b[sortColumn])) :  'number'  === typeof a[sortColumn] ?  b[sortColumn] - a[sortColumn] : String(b[sortColumn]).localeCompare(String(a[sortColumn]));
    });
   
    const sortedTransfersOutData = transfers_out.sort(function (a, b) {
        return 'asc' === sortTypeOut ? 'number'  === typeof a[sortColumnOut] ?  a[sortColumnOut] - b[sortColumnOut] :   String(a[sortColumnOut]).localeCompare(String(b[sortColumnOut])) :  'number'  === typeof a[sortColumnOut] ?  b[sortColumnOut] - a[sortColumnOut] : String(b[sortColumnOut]).localeCompare(String(a[sortColumnOut]));
    });

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);

        return true;
    }
    const handleSortColumnOut = (sortColumn, sortType) => {
        setSortColumnOut(sortColumn);
        setSortTypeOut(sortType);

        return true;
    }

    //transfers in filters
    const [transfersInFilterState, setTransfersInFilterState] = useState({
        players: { value: null },
        transfer_type: { value: null },
        position: { value: null },
        age: { value: null },
        club_from: { value: null }
    });

    const resetTransfersInPlayerFilter = () => {
        setTransfersInFilterState((prevState) => ({
            ...prevState,
            players: { value: null },
            transfer_type: { value: null },
            position: { value: null },
            age: { value: null },
            club_from: { value: null }
        }));
    }

    const applyTransfersInPlayerFilter = (text, e) => {
        e.preventDefault();

        setTransfersInFilterState((prevState) => ({
            ...prevState,
            players: { value: text },
        }));

        return true;

    }

    const applyTransfersInTransferTypesFilter = (text, e) => {
        e.preventDefault();

        setTransfersInFilterState((prevState) => ({
            ...prevState,
            transfer_type: { value: text },
        }));

        return true;

    }

    const applyTransfersInPositionFilter = (text, e) => {
        e.preventDefault();

        setTransfersInFilterState((prevState) => ({
            ...prevState,
            position: { value: text },
        }));

        return true;

    }

    const applyTransfersInAgeFilter = (text, e) => {
        e.preventDefault();

        setTransfersInFilterState((prevState) => ({
            ...prevState,
            age: { value: text },
        }));

        return true;

    }
    const applyTransfersInClubsFromFilter = (text, e) => {
        e.preventDefault();

        setTransfersInFilterState((prevState) => ({
            ...prevState,
            club_from: { value: text },
        }));

        return true;

    }

    const filterTransfersInPlayerArray = (item) => {

        let good = true;

        if (transfersInFilterState.players.value) {
            good = good && item.full_name.toLowerCase().includes(transfersInFilterState.players.value.toLowerCase());
        }

        if (transfersInFilterState.transfer_type.value) {
            good = good && String(item.transfer_type).toLowerCase().includes(String(transfersInFilterState.transfer_type.value).toLowerCase());
        }

        if (transfersInFilterState.position.value) {

            good = good && item.position.toLowerCase().includes(transfersInFilterState.position.value.toLowerCase());
        }

        if (transfersInFilterState.age.value) {
            good = good && String(item.age).toLowerCase().includes(String(transfersInFilterState.age.value).toLowerCase());
        }

        if (transfersInFilterState.club_from.value) {
            good = good && String(item.club_from).toLowerCase().includes(String(transfersInFilterState.club_from.value).toLowerCase());
        }

        return good;

    }

    //transfers out filters
    const [transfersOutFilterState, setTransfersOutFilterState] = useState({
        players: { value: null },
        transfer_type: { value: null },
        position: { value: null },
        age: { value: null },
        club_to: { value: null }
    });

    const resetTransfersOutPlayerFilter = () => {
        setTransfersOutFilterState((prevState) => ({
            ...prevState,
            players: { value: null },
            transfer_type: { value: null },
            position: { value: null },
            age: { value: null },
            club_to: { value: null }
        }));
    }

    const applyTransfersOutPlayerFilter = (text, e) => {
        e.preventDefault();

        setTransfersOutFilterState((prevState) => ({
            ...prevState,
            players: { value: text },
        }));

        return true;

    }

    const applyTransfersOutTransferTypesFilter = (text, e) => {
        e.preventDefault();

        setTransfersOutFilterState((prevState) => ({
            ...prevState,
            transfer_type: { value: text },
        }));

        return true;

    }

    const applyTransfersOutPositionFilter = (text, e) => {
        e.preventDefault();

        setTransfersOutFilterState((prevState) => ({
            ...prevState,
            position: { value: text },
        }));

        return true;

    }

    const applyTransfersOutAgeFilter = (text, e) => {
        e.preventDefault();

        setTransfersOutFilterState((prevState) => ({
            ...prevState,
            age: { value: text },
        }));

        return true;

    }
    const applyTransfersOutClubsToFilter = (text, e) => {
        e.preventDefault();

        setTransfersOutFilterState((prevState) => ({
            ...prevState,
            club_to: { value: text },
        }));

        return true;

    }

    const filterTransfersOutPlayerArray = (item) => {

        let good = true;

        if (transfersOutFilterState.players.value) {
            good = good && item.full_name.toLowerCase().includes(transfersOutFilterState.players.value.toLowerCase());
        }

        if (transfersOutFilterState.transfer_type.value) {
            good = good && String(item.transfer_type).toLowerCase().includes(String(transfersOutFilterState.transfer_type.value).toLowerCase());
        }

        if (transfersOutFilterState.position.value) {

            good = good && item.position.toLowerCase().includes(transfersOutFilterState.position.value.toLowerCase());
        }

        if (transfersOutFilterState.age.value) {
            good = good && String(item.age).toLowerCase().includes(String(transfersOutFilterState.age.value).toLowerCase());
        }

        if (transfersOutFilterState.club_to.value) {
            good = good && String(item.club_to).toLowerCase().includes(String(transfersOutFilterState.club_to.value).toLowerCase());
        }

        return good;

    }

    const PlayerTransferRow = ({ item, i, in_out = 'in' }) => {
        const { transfer_type, full_name, age, position, club_from, club_to, date, fee, uuid } = item;

        return (<><Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`rounded-md-2 rounded-top-end-0  rounded-bottom-end-0 p-2 text-decoration-none bg-dark-mode-400 mb-2 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}><div className=' rounded-1 bg-dark-mode-200 p-2 text-uppercase center-content text-center w-100 text-nowrap'>{transfer_type}</div></Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{full_name}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{age}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{statsFormatter(position)}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{in_out === 'in' ? club_from : club_to}</Link>
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`p-2 text-decoration-none bg-dark-mode-400 mb-2 border-start-0 border-end-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}>{dateFormatter(date)}</Link>
    
            <Link onMouseOver={() => setSelectedIndex(i)} onMouseOut={() => setSelectedIndex(null)} className={`rounded-md-2 rounded-top-start-0  rounded-bottom-start-0 p-2 text-decoration-none bg-dark-mode-400 mb-2 d-flex justify-content-between align-items-center border-start-0 border-on-hover-or-active white-icon-on-hover-or-active d-flex align-items-center ${selectedIndex === i ? 'active' : ''}`} to={`/playerdetail/${uuid}`}><div>{amountFormatter(fee)}</div><i className="white fa-regular fa-arrow-up-right text-neutral-gray-800"></i></Link></>)
    }

    return (
        <>{0 < sortedTransfersInData.length &&
            <div className='mb-5' id="team_transfers_in">
                <h3 className='fw-semi-bold '>Transfers in</h3>
                <div className='d-flex justify-content-start'>
                    <div className='border-0 bg-dark-mode-600 fw-normal small me-2 center-content px-3 cursor-pointer' onClick={resetTransfersInPlayerFilter}>All players</div>
                    <Dropdown className='me-2' onSelect={applyTransfersInPlayerFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Players
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All players</Dropdown.Item>
                            {[...new Set(transfers_in.map((item) => { return { full_name: item.full_name, last_name: item.last_name } }))].sort(function (a, b) {
                                return a.last_name.localeCompare(b.last_name)
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={item.full_name}>{item.full_name}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className='me-2' onSelect={applyTransfersInPositionFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Position
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item className='text-decoration-none text-white small bg-transparent py-1' href={null}>All positions</Dropdown.Item>
                            {[...new Set(transfers_in.map((item) => item.position))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{statsFormatter(item)}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyTransfersInAgeFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Age
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All ages</Dropdown.Item>
                            {[...new Set(transfers_in.map((item) => item.age))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyTransfersInTransferTypesFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small' >
                            Transfer type
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All transfer types</Dropdown.Item>
                            {[...new Set(transfers_in.map((item) => item.transfer_type))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyTransfersInClubsFromFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small' >
                            Club from
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All clubs</Dropdown.Item>
                            {[...new Set(transfers_in.map((item) => item.club_from))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="d-grid-md grid-gap-0 grid-md-7-cols fixed-1-row-6">
                    <div className='fw-semi-bold small p-2 d-flex align-items-center '></div>
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'last_name'} label={'Player name'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'date_of_birth'} label={'Age'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'position'} label={'Position'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'club_from'} label={'Club from'} sort_column={sortColumn} sort_type={sortType} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'date'} label={'Date'} sort_column={sortColumn} sort_type={sortType} />
          <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumn} field={'fee'} label={'Fee'} sort_column={sortColumn} sort_type={sortType} />

                    {sortedTransfersInData.filter(filterTransfersInPlayerArray).map((item, i) => {
                        return (<PlayerTransferRow key={i} item={item} i={i} in_out={'in'} />)
                    })}
                </div>
            </div>
        }{0 < sortedTransfersOutData.length &&
            <div className='mb-5' id='team_transfers_out'>
                <h3 className='fw-semi-bold '>Transfers out</h3>
                <div className='d-flex justify-content-start'>
                    <div className='border-0 bg-dark-mode-600 fw-normal small me-2 center-content px-3 cursor-pointer'  onClick={resetTransfersOutPlayerFilter}>All players</div>
                    <Dropdown className='me-2' onSelect={applyTransfersOutPlayerFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Players
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All players</Dropdown.Item>
                            {[...new Set(transfers_out.map((item) => { return { full_name: item.full_name, last_name: item.last_name } }))].sort(function (a, b) {
                                return a.last_name.localeCompare(b.last_name)
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i}  className='text-decoration-none text-white small bg-transparent py-1' href={item.full_name}>{item.full_name}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className='me-2' onSelect={applyTransfersOutPositionFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Position
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All positions</Dropdown.Item>
                            {[...new Set(transfers_out.map((item) => item.position))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i}  className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{statsFormatter(item)}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyTransfersOutAgeFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small'>
                            Age
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All ages</Dropdown.Item>
                            {[...new Set(transfers_out.map((item) => item.age))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i}  className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyTransfersOutTransferTypesFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small' >
                            Transfer type
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All transfer types</Dropdown.Item>
                            {[...new Set(transfers_out.map((item) => item.transfer_type))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i}  className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='me-2' onSelect={applyTransfersOutClubsToFilter} >
                        <Dropdown.Toggle variant="success" className='border-0 bg-dark-mode-600 fw-normal small' >
                            Club to
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-dark-mode-50 py-2 rounded-2'>
                            <Dropdown.Item key={-1} className='text-decoration-none text-white small bg-transparent py-1' href={null}>All clubs</Dropdown.Item>
                            {[...new Set(transfers_out.map((item) => item.club_to))].sort(function (a, b) {
                                return String(a).localeCompare(String(b))
                            }).map((item, i) => {
                                return (<Dropdown.Item key={i} className='text-decoration-none text-white small bg-transparent py-1' href={String(item)}>{item}</Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="d-grid-md grid-gap-0 grid-md-7-cols fixed-1-row-6">
                    <div className='fw-semi-bold small p-2 d-flex align-items-center '></div>
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumnOut} field={'last_name'} label={'Player name'} sort_column={sortColumnOut} sort_type={sortTypeOut} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumnOut} field={'date_of_birth'} label={'Age'} sort_column={sortColumnOut} sort_type={sortTypeOut} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumnOut} field={'position'} label={'Position'} sort_column={sortColumnOut} sort_type={sortTypeOut} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumnOut} field={'club_to'} label={'Club to'} sort_column={sortColumnOut} sort_type={sortTypeOut} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumnOut} field={'date'} label={'Date'} sort_column={sortColumnOut} sort_type={sortTypeOut} />
                    <SortableTableHeader addClass="fw-semi-bold small p-2 d-flex align-items-center" onHeaderClick={handleSortColumnOut} field={'fee'} label={'Fee'} sort_column={sortColumnOut} sort_type={sortTypeOut} />

                    {sortedTransfersOutData.filter(filterTransfersOutPlayerArray).map((item, i) => {
                        
                        return (<PlayerTransferRow key={i} item={item} i={i} in_out={'out'} />)
                    })}
                </div>
            </div>
            }
        </>
    )
}

export default TeamPlayerTransfers;