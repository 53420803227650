import PlayerIcon from "../../images/player_icon.png";


export default function PlayerTeamLozengeLarge({ data = {}, player = false }) {

    const { first_name, last_name, image, following } = data;
    const first_name_extracted = first_name ? first_name.split(' ')[0] : '';

    const imageLoadError = (e) => {
        e.target.src = PlayerIcon;
    };
    
    return (
        <div className={`d-flex ${player ? 'bg-dark-mode-50' : 'bg-dark-mode-300'}  white-icon-on-hover border-on-hover d-flex align-items-center justify-content-between rounded-5 w-auto`}>
            <div className={`d-flex  align-items-center` }>
                <div className="position-relative me-2">
                    {image ?
                        <img className={`object-fit-cover disc-55 disc-md-65 rounded-circle`} onError={imageLoadError} src={image} alt={first_name_extracted + ' ' + last_name} />
                        :
                        <img className={`object-fit-cover disc-55 disc-md-65 rounded-circle`} src={PlayerIcon} alt={first_name_extracted + ' ' + last_name} />

                    }
                    {following ? <div className='bg-fio-success-500 rounded-circle disc-3 p-1 center-content position-absolute bottom-0 end-0'> <i className='fa-light fa-check  small'></i></div> : <></>}
                </div>

                <p className="mb-0 me-3 small fw-normal">
                    {first_name_extracted} {last_name}
                </p>
            </div>
            <div className="me-3 d-flex flex-column justify-content-end"><i className="icon white text-neutral-gray-800 fa-light fa-arrow-up-right small"></i></div>
        </div>
    );
}
