import React from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import { TeamSVG, PlayerSVG } from './SvgIcons';

export default function PlayerCardResults({ data = {}, aiinsights = false, includeteam = true, stats = [] }) {

    const { first_name, last_name, image, positions = [] } = data;

    const team_image = data.team?.image || data.team_image;
    const team_name = data.team?.name || data.team || data.team_name;

    if (data.position_name)
        positions.push({ 'name': data.position_name });

    const fullName = `${first_name || ''} ${last_name || ''}`.trim();


    const { statsFormatter, firstNameExtracted } = HelperFunctions();

    return (
        <>
            <div className='h-100 d-flex flex-column justify-content-between align-items-center px-2half px-md-3 w-100'>
                <div className={`d-flex flex-column  pt-2half  pt-md-0 align-items-center w-100`}>
                    {image ?
                        <img className={`object-fit-cover disc-65 disc-md-9 rounded-circle bg-white mb-2 mb-md-3 me-md-0 me-0`} src={image} alt={fullName} />
                        :
                        <PlayerSVG className='disc-65 disc-md-9 mb-0 mb-md-3 me-2 me-md-0' />
                    }
                    <p className="mb-3 large fw-normal text-center">{firstNameExtracted(first_name)} <strong className="fw-semi-bold">{last_name}</strong></p>
                    {
                        0 < stats.length &&
                        <div className='mb-3 d-flex flex-column align-items-center align-items-md-center text-crayola-red-600 '>
                            {stats.map(([key, val], index) => {
                                if (val === undefined || val === null) return null;
                                const numericValue = typeof val === "string" && !isNaN(val) ? Number(val) : val;
                                return (
                                    <p className='d-block mb-0 extra-small text-center' key={index}>
                                        {(typeof numericValue === "number")
                                            ? (Number.isInteger(numericValue)
                                                ? numericValue.toLocaleString()
                                                : numericValue.toFixed(1))
                                            : numericValue}
                                        {key !== "position" ? " " + statsFormatter(key) : ""}
                                    </p>
                                );
                            })}
                        </div>
                    }

                </div>
                {aiinsights && (
                    <div className='d-flex align-items-start align-items-md-center text-crayola-red-600 mb-2 mb-md-3 w-100 w-md-auto px-6 px-md-3'>
                        <div className='d-none d-md-block'>
                            <i className='fa fa-sparkles me-3'></i>
                        </div>
                        <div className='d-block d-md-none'>
                            <i className='fa fa-sparkles me-3 extra-small'></i>
                        </div>
                        <p className='d-none d-md-block mb-0 small'>First cap for national team at 18 years old</p>
                        <p className='d-block d-md-none mb-0 extra-small'>First cap for national team at 18 years old</p>
                    </div>
                )}
            </div>
            <div className='bg-md-dark-mode-50 w-100 pb-2 pt-md-2 px-2half px-md-3 center-content rounded-2 rounded-top-start-0 rounded-top-end-0'>
                <span className='d-none d-md-inline'>
                    {team_image ?
                        <img className="object-fit-cover disc-35 disc-md-4 rounded-circle me-2" src={team_image} alt={team_name || "Team"} />
                        :
                        <TeamSVG />
                    }
                </span>
                <p className="mb-0 small text-neutral-gray-700 ">{team_name || ""}</p>
            </div>

        </>
    );
}
