import React from 'react'
import { TeamSVG } from './SvgIcons'

const TeamCardTypeahead = ({ data }) => {
  const { name, image } = data;

  return (
    <div className='px-2half py-3 d-flex justify-content-start align-items-center'>
      <div className={`d-flex align-items-center me-1`}>
        {
          image ? (
            <img
              className="object-fit-cover disc-65 rounded-circle bg-white mb-0 me-3"
              src={image}
              alt={name}
            />
          ) : (
            <TeamSVG className="disc-65 mb-0 me-3" />
          )
        }
        <p className="d-block mb-0 fw-normal text-center small text-decoration-none text-white">
          {name}
        </p>
      </div>
    </div>
  )
}

export default TeamCardTypeahead