import React, { useState } from 'react'
import DetailReports from '../components/Reports/DetailReports'
import Breadcrumb from '../templates/Breadcrumb';
import Modal from '../templates/Modal';

const Reports = () => {
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Reports' }
    ];
    return (
        <div>
            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            <Breadcrumb items={breadcrumbItems} />
            <DetailReports onError={(message) => { setShowModal(true); setModalMessage(message) }} />
        </div>
    )
}

export default Reports