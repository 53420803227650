import React, { useState, useEffect } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import Loader from "../templates/Loader";

const TeamNews = ({ data = [] }) => {

    const [loadingData, setLoadingData] = useState(false);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [visibleCards, setVisibleCards] = useState(2);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 2);
    };

    useEffect(() => {
        setResultsTotal(data?.length);
        setLoadingData(false);
    }, [data]);

    const TeamNewsCard = ({ item = {} }) => {

        const { dateFormatter, stringTruncate } = HelperFunctions();
        const {  source_name, source_type, source_url, news_date, text } = item;
        return (
            <a  href={source_url} target='_blank' rel='noreferrer' className="text-decoration-none rounded-3 bg-dark-mode-300  p-3 d-flex flex-column justify-content-between">
                <div className="d-flex flex-column h-100  w-100 justify-content-between">
                    <div className="d-flex justify-content-between justify-content-start mb-2">
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="d-flex align-items-center justify-content-start me-2">
                                <img className="object-fit-contain w-px-38 h-px-38 rounded-2 flex-none bg-white object-fit-cover" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=64'} alt="icon" />
                            </div>
                            <div>
                                <p className="mb-0 small">{source_name}</p>
                                <p className=" text-neutral-gray-800 mb-0 small">{source_type !== 'api' ? source_type : ''}</p>
                            </div>
                        </div>
                        <div className="small">{news_date ? dateFormatter(news_date) : "25th July 2024"}</div>
                    </div>
                    <p className="d-md-block d-none mb-0 small">{stringTruncate(text)}</p>
                    <p className="extra-small mb-0  d-md-none d-block">{stringTruncate(text)}</p>
                </div>
            </a>
        )

    }

    const viewResults = data?.slice(0, visibleCards);

    return (
        <> {0 === data?.length ? <div className="ratio ratio-4x1">
            <div className='center-content'>
                <Loader />
            </div>
        </div> :
            <> <h3 className='fw-semi-bold'>News feed</h3>
                {viewResults?.map((item, i) => {

                    return (<div key={i} className='my-2'><TeamNewsCard item={item} i={i} /></div>)
                })}
                {loadingData && <Loader />}
                {visibleCards < resultsTotal && (
                    <div className="center-content"> <button
                        onClick={loadMoreHandler}
                        className="btn btn-lg bg-crayola-red-500 px-55 rounded-pill small fw-semi-bold position-relative"
                        disabled={loadingData}
                    >
                        Show more {loadingData && <span className="spinner-border spinner-border-sm position-absolute top-50 start-50 text-white mx-n2 mt-n2" role="status" aria-hidden="true"></span>}
                    </button></div>
                )}

            </>
        }
        </>
    )
}

export default TeamNews;