import React from 'react'
import { Link } from 'react-router-dom'

const DetailReports = ({ onError }) => {
  return (
    <div className='min-vh-100 position-relative'>
      <div className='mb-5'>
        <h2 className='mb-2 fw-extra-bold'>Reports</h2>
        <p className='mb-0 text-neutral-gray-700'>Select a report to start your analysis.</p>
      </div>

      <div className='d-grid grid-md-3-cols grid-1-cols grid-gap-3'>
        {false && <div className='p-4 bg-dark-mode-500 rounded-3 white-icon-on-hover border-on-hover target-bg'>
          <p className='large fw-semi-bold mb-2'>Head to head analysis</p>
          <div className='d-flex align-items-end gap-4'>
            <p className='text-neutral-gray-700 mb-0 small z-index-2'>Choose two teams to compare. Start with an existing team you have a fixture against, tweak your squad with players from your bench or add new players from other teams.</p>
            <i className="icon white fa-regular fa-arrow-up-right text-neutral-gray-700"></i>
          </div>
        </div>}
        <Link to={'/reports/team-transfers-report'} className='p-4 bg-dark-mode-500 rounded-3 white-icon-on-hover border-on-hover target-bg text-decoration-none'>
          <p className='large fw-semi-bold mb-2 mt-3'>Team transfer analysis</p>
          <div className='d-flex align-items-end gap-4'>
            <p className='text-neutral-gray-700 mb-0 small z-index-2'>Choose a team and review the transfer information for a given time period. Understand how your rivals have prepared for the season ahead.</p>
            <i className="icon white fa-regular fa-arrow-up-right text-neutral-gray-700"></i>
          </div>
        </Link>
      </div>

      <div className="d-md-flex justify-content-between align-items-center bg-dark-mode-300 rounded-2 position-absolute bottom-0 text-decoration-none px-md-6 px-4 py-md-5 py-4 w-100">
        <div>
          <h4 className="mb-0 fw-extra-bold">Need something custom?</h4>
          <p className="mb-0 text-neutral-gray-600">Request a consultation to see if we can save you time and money by automating a report within you team.</p>
        </div>
        <div className="w-100 w-md-auto translate-icon-on-hover">
          <a href="mailto:sarah@sportskinetic.ai" className="btn btn-md bg-crayola-red-500 w-100 text-decoration-none small fw-semi-bold mt-md-0 mt-3 rounded-2 fw-normal">Contact us<i className="ms-2 icon fa-solid fa-arrow-right small"></i></a>
        </div>
      </div>
    </div>
  )
}

export default DetailReports