
import React, { useEffect, useState, useCallback } from "react";
import TeamCard from "../templates/TeamCard";
import Loader from "../templates/Loader";
import TeamPlayerInformation from "../templates/TeamPlayerInformation";
import TeamPlayerTransfers from "../templates/TeamPlayerTransfers";
import TeamNews from "../templates/TeamNews";
import Modal from '../templates/Modal';
import { useParams } from 'react-router-dom';
import { apiService } from '../services/apiService';
import { authenticationService } from '../services/authenticationService';
import Breadcrumb from '../templates/Breadcrumb';
function TeamReport() {

    const { id } = useParams();

    const [loadingData, setLoadingData] = useState(true);
    const [teamData, setTeamData] = useState(null);
    const [teamNewsData, setTeamNewsData] = useState(null);
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Reports', to: '/reports' },
        { label: 'Team report', to: '/reports/team-transfers-report' },
        { label: `${teamData?.name}` }
    ];

    const  onError = useCallback((message) => { setShowModal(true); setModalMessage(message) }, [])

    useEffect(() => {
        setLoadingData(true);
        apiService
            .apiGet(process.env.REACT_APP_API_URL + `/team/report/${id}`)
            .then((resp) => {

                setTeamData(resp.data);
                setLoadingData(false);
            })
            .catch((error) => {
                if (error.message === '401') {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }
            });

    }, [id, onError]);

    useEffect(() => {

        apiService
            .apiGet(process.env.REACT_APP_API_URL + `/team/report/transfer_news/${id}`)
            .then((resp) => {
                setTeamNewsData(resp.data);
            })
            .catch((error) => {
                if (error.message === '401') {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }
            });

    }, [id, onError]);


    return (
        <>
          {showModal &&
                        <Modal message={modalMessage} onClose={() => setShowModal(false)} />
                    }
            {loadingData ? <div className="ratio ratio-4x1">
                <div className='center-content'>
                    <Loader />
                </div>
            </div> :

                <div>
                    <Breadcrumb items={breadcrumbItems} />
                    <div className="d-lg-flex gap-5">
                        <div className="flex-grow-1">


                            <div className="d-flex d-md-none mb-4">
                                <a href="/" className="d-flex align-items-center text-decoration-none">
                                    <i className="fa-regular fa-arrow-left me-3"></i>
                                </a>
                            </div>
                            <div className="mb-5">
                                <TeamCard data={teamData} />
                            </div>
                            <TeamPlayerInformation data={teamData} />
                            <TeamPlayerTransfers data={teamData} />
                        </div>
                        {teamNewsData?.length > 0 && (
                            <div className="flex-grow-0 w-px-lg-450 min-w-px-lg-450">
                                <TeamNews data={teamNewsData} />
                            </div>
                        )}
                    </div>
                </div>}
        </>
    );


}

export default TeamReport;
