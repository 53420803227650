import React from 'react';

const TeamCard = ({ data = {} }) => {

    const { name, league, league_position, image, previous_results, squad_statistics } = data;

    const formatLostWon = (lost_won) => {
        switch (lost_won) {
            case 'lost':
                return 'L';
            case "won":
                return 'W';
            case "draw":
                return 'D';
            default:
                return '';
        
        }
    }
    const bgLostWon = (lost_won) => {
        switch (lost_won) {
            case 'lost':
                return 'bg-fio-error-500';
            case "won":
                return 'bg-fio-success-500';
            case "draw":
                return 'bg-dark-mode-300';
            default:
                return '';
        
        }
    }
    const scrollTo = (e) => {
        e.preventDefault();
        const element = document.getElementById(e.currentTarget.hash.replace('#', ''));

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop + 30 : 0
            });
        }, 100);
    }

    return (
        <>
            <div className=''>
                <div className='d-flex justify-content-between align-items-md-center mb-5'>
                    <div className='d-flex justify-content-between'>
                        {image &&
                            <img src={image} alt={name} className='object-fit-contain disc-10 me-4' />
                        }
                        <div className='d-flex flex-column justify-content-between'>
                            <div>
                                <h2 className='mb-0 fw-semi-bold '>{name}</h2>
                                <p className=' fw-semi-bold text-neutral-grey-200 mb-0 '>{league}{league_position && <>&nbsp;&middot;&nbsp;{league_position}</>}</p>
                            </div>
                            {0 < previous_results.length && <div className='d-flex'>{previous_results.map((item, i) => {
                                return (<div key={i} className={`rounded-circle me-2 disc-45 center-content small  fw-semi-bold  ${bgLostWon(item.won_lost)}`}>{formatLostWon(item.won_lost)}</div>)

                            })}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className='pt-4 mb-5'>
                    <h3 className='fw-semi-bold '>Squad statistics</h3>
                    <div className="d-grid-md grid-gap-3 grid-md-4-cols">
                        <div className='rounded-3 bg-dark-mode-700 d-flex flex-column justify-content-between p-3'>
                            <p className='fw-semi-bold text-neutral-gray-800 mb-0 small'>{squad_statistics?.squad_age?.label}</p>
                            <h4 className='fw-semi-bold  mb-0'>{squad_statistics?.squad_age?.average}</h4>
                            <div>
                                <p className='fw-semi-bold mb-0 extra-small'><span className='text-neutral-gray-700'>Min.</span> {squad_statistics?.squad_age?.min}</p>
                                <p className='fw-semi-bold text-neutral-grey-200  extra-small mb-0'><span className='text-neutral-gray-700'>Max.</span> {squad_statistics?.squad_age?.max}</p>
                            </div>
                        </div>
                        <div className='rounded-3 bg-dark-mode-700 d-flex flex-column justify-content-between p-3'>
                            <p className='fw-semi-bold text-neutral-gray-800 mb-0 small'>{squad_statistics?.squad_value?.label}</p>
                            <h4 className='fw-semi-bold  mb-0'>{squad_statistics?.squad_value?.total}</h4>
                            <div>
                                <p className='fw-semi-bold mb-0 extra-small'><span className='text-neutral-gray-700'>Min.</span> {squad_statistics?.squad_value?.min}</p>
                                <p className='fw-semi-bold text-neutral-grey-200  extra-small mb-0'><span className='text-neutral-gray-700'>Max.</span> {squad_statistics?.squad_value?.max}</p>
                            </div>
                        </div>
                        <div className='rounded-3 bg-dark-mode-700 d-flex flex-column justify-content-between p-3'>

                            <p className='fw-semi-bold text-neutral-gray-800 mb-0 small'>{squad_statistics?.net_transfer?.label}</p>
                            <h4 className='fw-semi-bold  mb-0'>{squad_statistics?.net_transfer?.total}</h4>


                        </div>
                        <div className="d-grid grid-gap-3">
                            <a onClick={scrollTo} className='text-decoration-none rounded-3 bg-dark-mode-700 d-flex flex-column justify-content-between p-3' href="#team_transfers_in">
                                <p className='fw-semi-bold text-neutral-gray-800 mb-0 small'>{squad_statistics?.transfer_in?.label}</p>
                                <h6 className='fw-semi-bold  mb-0'>{squad_statistics?.transfer_in?.total}</h6>
                            </a>
                            <a onClick={scrollTo} className='text-decoration-none rounded-3 bg-dark-mode-700 d-flex flex-column justify-content-between p-3' href="#team_transfers_out">
                                <p className='fw-semi-bold text-neutral-gray-800 mb-0 small'>{squad_statistics?.transfer_out?.label}</p>
                                <h6 className='fw-semi-bold  mb-0'>{squad_statistics?.transfer_out?.total}</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default TeamCard;