import React, { useEffect, useState } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import { PlayerSVG } from './SvgIcons';

export default function PlayerCardTypeahead({ data, status, onSelect, noImg }) {
    const { firstNameExtracted } = HelperFunctions();
    const { first_name, last_name, currentTeam, positions, following, image } = data;

    // Normalize following value (true/false or 1/0)
    const normalizeFollowing = (following) => {
        if (typeof following === 'boolean') {
            return following;
        }
        return following === 1;
    };

    const [isFollowed, setIsFollowed] = useState(normalizeFollowing(following));

    const handleFollowClick = () => {
        if (!isFollowed) {
            setIsFollowed(true);
            onSelect(data);
        }
    };

    useEffect(() => {
        setIsFollowed(normalizeFollowing(data.following));
    }, [data]);

    return (
            <div className="px-2half py-3 d-flex justify-content-between align-items-center">
                <div className={`d-flex ${!noImg ? 'align-items-center' : 'flex-column align-items-start'} me-1`}>
                    {!noImg && (
                        image ? (
                            <img
                                className="object-fit-cover disc-65 rounded-circle bg-white mb-0 me-3"
                                src={image}
                                alt={first_name}
                            />
                        ) : (
                            <PlayerSVG className="disc-65 mb-0 me-3" />
                        )
                    )}
                    <p className="d-block mb-0 fw-normal text-center small text-decoration-none text-white">
                        {firstNameExtracted(first_name) || ''}{' '}
                        <strong className="fw-semi-bold">{last_name || ''}</strong>
                    </p>
                    {!status && positions.length > 0 && (
                        <p className="d-block mb-0 extra-small text-wrap text-neutral-gray-700 text-start">
                            {positions.map(position => position?.positionName).join('/')}
                        </p>
                    )}
                    <p className="d-block mb-0 extra-small text-neutral-gray-700 text-center">
                        {currentTeam}
                    </p>
                </div>
                {status && (
                    <div
                        className={`btn text-center rounded-pill badge rounded-pill border-1 small fw-normal py-1 px-2half ${isFollowed
                            ? 'text-neutral-gray-800 bg-zero-dark-300'
                            : 'text-crayola-red-300 border border-crayola-red-300'
                            }`}
                        onClick={!isFollowed ? handleFollowClick : undefined}
                    >
                        {isFollowed ? 'Following' : 'Follow'}
                    </div>
                )}
            </div>
    );
}
